import { NgbDateStruct } from "@ng-bootstrap/ng-bootstrap";
import { BaseEntity, IBoarding, InvoiceCounterNumber } from "@shared/models";

export class Ticket extends BaseEntity {
  public invoiceNumber: string;
  public boarding: IBoarding | null;
  public dropOff: IBoarding | null;
  public check1: boolean;
  public check2: boolean;
  public check3: boolean;
  public detail: string;
  public tripSeat: TripSeat | null;
  public reserveNumber: string;
  public card: boolean;
  public cash: boolean;
  public checkedTime: string | null;
  public soldBy: string;
  public cardReference: string;
  public paymentType: string;
  public finalPrice: number;
  public hasDiscount: boolean;
  public date: string;
  public dateUI?: string;
  public basedPrice: number;
  public discount: number;
  public rate: number;
  public routeRate: RouteRate | null;
  public client: BaseEntity | null;

  /* UI only flags */
  public canModifyBoardings?: boolean;

  constructor(obj: Partial<Ticket>) {
    super(obj);
    this.id = obj.id || 0;
    this.invoiceNumber = obj.invoiceNumber || "";
    this.boarding = obj.boarding || null;
    this.dropOff = obj.dropOff || null;
    this.check1 = obj.check1 || false;
    this.check2 = obj.check2 || false;
    this.check3 = obj.check3 || false;
    this.detail = obj.detail || "";
    this.reserveNumber = obj.reserveNumber || "";
    this.tripSeat = obj.tripSeat || null;
    this.reserveNumber = obj.reserveNumber || "";
    this.card = obj.card || false;
    this.cash = obj.cash || false;
    this.checkedTime = obj.checkedTime || "";
    this.soldBy = obj.soldBy || "";
    this.cardReference = obj.cardReference || "";
    this.paymentType = obj.paymentType || "";
    this.finalPrice = obj.finalPrice || 0;
    this.date = obj.date || "";
    this.basedPrice = obj.basedPrice || 0;
    this.discount = obj.discount || 0;
    this.hasDiscount = obj.hasDiscount || false;
    this.rate = obj.rate || 0;
    this.routeRate = obj.routeRate || null;
    this.client = obj.client || null;
  }
}

interface TripSeat {
  id: number;
  seatNumber: number;
  trip: Trip;
  routeRate: RouteRate;
}

interface Trip {
  id: number;
  schedule: Schedule;
  date: string;
  seats: number;
}

interface Schedule {
  id: number;
  departureTime: string;
}

interface RouteRate {
  route: {
    id: number;
    name: string;
    invoiceCounterNumber: InvoiceCounterNumber;
  };
}
